<template>
  <v-card class="pa-0" :flat="flat" :color="cardColor">
    <v-app-bar v-if="toolbarTitle" color="transparent" flat>
      <v-toolbar-title>
        {{ toolbarTitle }}
        <div v-if="toolbarSubTitle" class="text-body-2">
          {{ toolbarSubTitle }}
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <slot name="extra"></slot>
      <v-spacer></v-spacer>
      <v-btn
        v-if="alternateButton && alternateButtonLink"
        icon
        :href="alternateButtonLink"
        target="_blank"
      >
        <v-icon>{{ alternateButton }}</v-icon>
      </v-btn>
      <v-btn
        v-if="alternateButton && alternateButtonFunction"
        icon
        @click="alternateButtonFunction"
      >
        <v-icon>{{ alternateButton }}</v-icon>
      </v-btn>
      <v-menu
        v-if="hasCollectionButton"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="200"
        top
        offset-y
      >
        <!-- <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-playlist-plus</v-icon>
          </v-btn>
        </template> -->

        <v-card>
          <v-list>
            <v-list-item>
              <!-- <v-list-item-icon>
                <v-icon>mdi-playlist-plus</v-icon>
              </v-list-item-icon> -->
              <v-list-item-content>
                <v-list-item-title>Add to Collection</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item class="mt-3 ">
              <v-select
                :items="['Internal Collection', 'For Audit', 'Level 2']"
                :menu-props="{ maxHeight: '400' }"
                label="Select Collectiion"
                dense
              ></v-select>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <slot />
  </v-card>
</template>

<script>
export default {
  props: {
    flat: Boolean,
    cardColor: {
      type: String,
      default: "dashCard"
    },
    toolbarTitle: String,
    toolbarSubTitle: String,
    hasCollectionButton: {
      type: Boolean,
      default: true
    },
    alternateButton: String,
    alternateButtonLink: String,
    alternateButtonFunction: Function
  },
  data() {
    return {
      menu: false
    };
  }
};
</script>

<style></style>
