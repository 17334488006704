<template>
  <v-container v-if="sim" fluid class="mt-4 py-6">
    <v-slide-y-transition>
      <header v-if="showHeader" class="mb-12 d-flex flex-column align-center">
        <div class="d-flex">
          <!-- <v-icon left large class="mr-8">mdi-creation</v-icon> -->
          <div>
            <div class="text-h6 text-uppercase primary--text">
              <v-icon color="primary" left class="mr-3">mdi-creation</v-icon>
              <span style="vertical-align: middle">Simulation</span>
            </div>
            <h1 class="text-h4 text-sm-h4 mb-6">
              {{ sim.scenario }}
            </h1>
          </div>
        </div>
        <v-row>
          <v-col class="text-no-wrap">
            <strong>Basis:</strong> {{ sim.basis }}
          </v-col>
          <v-col class="text-no-wrap">
            <strong>Type:</strong> {{ sim.type }}
          </v-col>
          <v-col class="text-no-wrap">
            <strong>Timeline:</strong> {{ sim.timeline }}
          </v-col>
        </v-row>
      </header>
    </v-slide-y-transition>

    <v-row>
      <v-slide-y-transition>
        <v-col v-if="showLeftContent" cols="12" md="5">
          <BaseCardWithHeader
            :hasCollectionButton="false"
            toolbarTitle="Reduction Threshold for Benefit Trigger"
            toolbarSubTitle="Select a threshold to alter the simulation"
            ><div class="pa-8 text-center">
              <v-slider
                v-model="thresholdSelection"
                :tick-labels="ticksLabels"
                :max="4"
                step="1"
                ticks="always"
                tick-size="3"
              ></v-slider>
              <!-- <v-btn
                color="primary darken-2"
                class="mt-5"
                @click="handleRunSimulation"
                >Run Simulation</v-btn
              > -->
            </div></BaseCardWithHeader
          >
          <v-alert
            prominent
            outlined
            icon="mdi-information-outline"
            class="mt-6"
          >
            {{ sim.thresholdDesc }}
          </v-alert>

          <v-alert dense outlined color="light-blue lighten-3" class="mt-6">
            <v-row align="center">
              <v-col class="grow">
                <v-icon left color="light-blue lighten-3" class="mr-3"
                  >mdi-creation</v-icon
                >
                <strong>Next:</strong>
                {{
                  sim.id !== simulations[0].id
                    ? simulations[0].scenario
                    : simulations[1].scenario
                }}
              </v-col>
              <v-col class="shrink">
                <v-btn
                  @click="handleSimulate(sim.id)"
                  color="primary darken-2"
                  text
                  >Simulate</v-btn
                >
              </v-col>
            </v-row>
          </v-alert>
        </v-col>
      </v-slide-y-transition>
      <v-slide-y-transition>
        <v-col v-if="showRightContent" cols="12" md="7">
          <template>
            <BaseCardWithHeader :hasCollectionButton="false">
              <div class="pa-6 text-h6 text-center">
                <div class="mb-4">
                  <v-tooltip top max-width="350" color="background">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-help-circle-outline</v-icon>
                      </v-btn>
                    </template>
                    <span
                      >Estimates based on PlanGap’s expert in-depth analysis of
                      legislation and how it would affect a random sampling of
                      typical annuity purchasers based on 3rd party data.</span
                    >
                  </v-tooltip>
                  We estimate
                  <strong class="primary--text">{{
                    sim.claimEligible[thresholdSelection]
                  }}</strong>
                  of policy owners may be eligible for a PlanGap claim
                  <p class="text-subtitle-2">
                    <template v-if="sim.fileNamePrefix === 'fra'">
                      {{
                        `Birth years after ${fraAffectedBirthYear -
                          1} would be affected based upon a ${thresholdSelection +
                          1}% benefit trigger`
                      }}</template
                    >
                    <template v-else>
                      {{
                        `Clients with income over ${meansAffectedIncome} while on Social Security would be affected based upon a ${thresholdSelection +
                          1}% benefit trigger`
                      }}
                    </template>
                  </p>
                </div>
                <div>
                  <template v-if="sim.visualization === 'chart'">
                    <SimulationChart
                      :chartData="sim.chartData"
                      :threshold="thresholdSelection + 1"
                  /></template>
                  <template v-else>
                    <v-data-table
                      :headers="fraTableHeaders"
                      :items="checkFraData"
                      :items-per-page="5"
                      :single-select="false"
                      item-key="Policy Number"
                      :loading="tableIsLoading"
                      loading-text="Loading... Please wait"
                      class="mt-4 transparent"
                    >
                      <template v-slot:header.trend="{ header }">
                        <v-tooltip top max-width="200" color="background">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on">
                              <v-icon small>mdi-help-circle-outline</v-icon>
                            </v-btn>
                          </template>
                          <div>
                            <p>
                              <v-icon color="green" medium dark left>
                                mdi-trending-up
                              </v-icon>
                              <span>No Benefit Triggered</span>
                            </p>
                            <p class="d-flex align-center">
                              <v-icon color="grey" medium dark left>
                                mdi-trending-down
                              </v-icon>
                              <span>No Benefit Triggered</span>
                            </p>
                            <p class="mb-0">
                              <v-icon color="red" medium dark left>
                                mdi-trending-down
                              </v-icon>
                              <span>Benefit Triggered</span>
                            </p>
                          </div>
                        </v-tooltip>
                        {{ header.text }}
                      </template>

                      <template v-slot:item.projected_2032_index="{ item }">
                        {{ item.projected_2032_index | toCurrency }}
                      </template>

                      <template v-slot:item.post_change_2033_index="{ item }">
                        {{ item.post_change_2033_index | toCurrency }}
                      </template>

                      <template v-slot:item.trend="{ item, index }">
                        <v-icon :color="getTrendColor(item, index)" medium dark>
                          {{ getTrendIcon(item) }}
                        </v-icon>
                      </template>
                    </v-data-table>
                  </template>
                </div>
                <div class="mt-5 text-center">
                  <v-btn
                    :disabled="
                      sim.fileNamePrefix === 'fra' && thresholdSelection >= 3
                    "
                    color="primary darken-2"
                    @click="handleOpenReportDialog()"
                    >View Sample Policyholder Report</v-btn
                  >
                </div>
              </div>
            </BaseCardWithHeader>
          </template>

          <!-- <template v-if="!isLoading && !simulationHasRun">
            <div class="d-flex align-center justify-center text-center">
              <span>
                <v-icon left>mdi-arrow-left</v-icon>Select a Threshold to Run
                Simulation</span
              >
            </div>
          </template>
          <template v-if="isLoading">
            <div v-text="loadingText"></div>
            <v-progress-circular
              v-if="isLoading"
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </template> -->
        </v-col>
      </v-slide-y-transition>
    </v-row>

    <v-dialog
      overlay-opacity="0.8"
      overlay-color="#2C4779"
      v-model="simulationReportDialog"
      transition="scroll-y-transition"
      max-width="950"
      :scrollable="true"
    >
      <div>
        <BaseCardWithHeader
          :hasCollectionButton="false"
          toolbarTitle="Policy Holder Report"
          toolbarSubTitle="Based on the selected threshold, the following policy holders would be eligible for a PlanGap claim."
          alternateButton="mdi-close"
          :alternateButtonFunction="this.closeDialog"
        >
          <v-data-table
            :headers="reportTableHeaders"
            :items="checkTableData"
            :items-per-page="10"
            :single-select="false"
            item-key="Policy Number"
            :loading="tableIsLoading"
            loading-text="Loading... Please wait"
            class="mt-4 transparent"
          >
            <!-- <template v-slot:top>
            <v-container class="mt-2">
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    v-model="selectedYears"
                    :items="yearOptions"
                    :menu-props="{ maxHeight: '400' }"
                    @blur="onYearsChange($event)"
                    label="Select Years"
                    multiple
                    dense
                  ></v-select>
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select
                    v-model="selectedIndex"
                    :items="indexOptions"
                    label="Select Index"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
          </template> -->

            <!-- <template v-slot:item.year1Index="{ item }">
            {{ item.year1Index | toCurrency }}
          </template>

          <template v-slot:item.year2Index="{ item }">
            {{ item.year2Index | toCurrency }}
          </template>

          <template v-slot:item.trend="{ item }">
            <v-icon :color="getTrendColor(item.trend)" medium dark>
              {{ getTrendIcon(item.trend) }}
            </v-icon>
          </template> -->
          </v-data-table>
        </BaseCardWithHeader>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import BaseCardWithHeader from "@/components/BaseCardWithHeader";
import SimulationChart from "@/components/SimulationChart";

export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  components: {
    BaseCardWithHeader,
    SimulationChart
  },
  data() {
    return {
      simulationReportDialog: false,
      isLoading: false,
      loadingText: "Building Simulation...",
      showHeader: false,
      showLeftContent: false,
      showRightContent: false,
      thresholdSelection: 1,
      ticksLabels: ["1%", "2%", "3%", "4%", "5%"],
      simulationHasRun: false,
      tableIsLoading: false,
      reportTableHeaders: [
        {
          text: "Policy Number",
          sortable: true,
          value: "policy_number"
        },
        {
          text: "First Name",
          align: "start",
          sortable: true,
          value: "owner_first_name"
        },
        {
          text: "Last Name",
          sortable: true,
          value: "owner_last_name"
        },
        {
          text: "Age",
          sortable: true,
          value: "age"
        },
        {
          text: "effective_date",
          sortable: true,
          value: "effective_date"
        },
        {
          text: "initial_purchase_payment",
          sortable: true,
          value: "initial_purchase_payment"
        },
        {
          text: "Annual Household income",
          sortable: true,
          value: "annual_household_income"
        }
      ],
      fraTableHeaders: [
        {
          text: "Birth Year",
          sortable: true,
          value: "birth_year"
        },
        {
          text: "Projected 2032 Index Value",
          sortable: true,
          value: "projected_2032_index"
        },
        {
          text: "Post-Change Projected 2033 Index Value",
          sortable: true,
          value: "post_change_2033_index"
        },
        {
          text: "Change %",
          sortable: true,
          value: "change"
        },
        {
          text: "Trend",
          sortable: true,
          value: "trend",
          align: "center"
        }
      ],
      fraTableData: [],
      fraAffectedBirthYear: null,
      checkTableData: [],
      publicPath: process.env.BASE_URL
    };
  },
  mounted() {
    // console.log("simulations", this.simulations);
    // this.sim = this.simulations[0];

    const that = this;
    setTimeout(() => {
      that.showHeader = true;
    }, 300);
    setTimeout(() => {
      that.showLeftContent = true;
    }, 400);
    setTimeout(() => {
      that.showRightContent = true;
    }, 500);

    if (this.sim.fileNamePrefix === "fra") this.loadFraTableData();
  },
  computed: {
    ...mapGetters("simulationsState", ["simulations"]),
    sim() {
      const sim = this.simulations.find(s => {
        return s.id === this.id;
      });
      return sim;
    },
    checkFraData() {
      return this.fraTableData;
    }
  },
  watch: {
    checkFraData() {
      if (this.sim.fileNamePrefix === "fra") {
        this.updateFraData();
      }
    },
    thresholdSelection: {
      immediate: true,
      handler: function(val) {
        if (this.sim.fileNamePrefix === "fra") {
          this.updateFraData(val);
        } else {
          this.updateMeansData(val);
        }
      }
    }
  },
  methods: {
    updateMeansData(thresh) {
      this.meansAffectedIncome = this.formatCurrency(
        this.sim.chartData[thresh].amount
      );
    },
    updateFraData(thresh) {
      let threshold = thresh;
      if (!thresh) threshold = this.thresholdSelection;

      const tempItems = [];

      this.checkFraData.forEach(item => {
        item.isAffected = false;
        if (item.change < -1 * (threshold + 1)) {
          item.isAffected = true;
          tempItems.push(item.birth_year);
        }
      });

      this.fraAffectedBirthYear = Math.min(...tempItems);
    },
    async loadFraTableData() {
      if (this.sim.fileNamePrefix !== "fra") {
        return;
      }
      //   const thresh = (threshold + 1).toString();
      const fetchPath = `/simulationData/fra-sim-data.json`;

      let tableData;
      await fetch(fetchPath, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
        .then(r => r.json())
        .then(
          json => {
            tableData = json;
            // console.log("checkTableData JSON ", json);
          },
          response => {
            console.log("Error loading json:", response);
          }
        );

      this.fraTableData = tableData;
    },
    async loadReportTableData(threshold) {
      //   const thresh = (threshold + 1).toString();
      const fetchPath = `/simulationData/${
        this.sim.fileNamePrefix
      }-${threshold + 1}.json`;
      console.log("THREHOLD", fetchPath);
      let tableData;
      await fetch(fetchPath, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        }
      })
        .then(r => r.json())
        .then(
          json => {
            tableData = json;
            // console.log("checkTableData JSON ", json);
          },
          response => {
            console.log("Error loading json:", response);
          }
        );

      return tableData;
    },
    getTrendColor(item) {
      const threshold = this.thresholdSelection + 1;

      if (item.change > 0) return "green";

      if (item.change < -1 && threshold === 1) {
        return "red";
      } else if (item.change < -2 && threshold === 2) {
        return "red";
      } else if (item.change < -3 && threshold === 3) {
        return "red";
      } else if (item.change < -4 && threshold === 4) {
        return "red";
      } else if (item.change < -5 && threshold === 5) {
        return "red";
      } else {
        return "grey";
      }
    },
    getTrendIcon(item) {
      if (item.change > 0) {
        return "mdi-trending-up";
      } else if (item.change < 0) {
        return "mdi-trending-down";
      } else {
        return "mdi-trending-neutral";
      }
    },
    handleRunSimulation() {
      // pass this.thresholdSelection into component for chart and details
      //   this.claimPercentage = this.sim.claimEligible[this.thresholdSelection]
      this.simulationHasRun = false;
      //this.isLoading = true;
      //   setTimeout(() => {
      //     this.loadingText = "Creating visualization...";
      //   }, 1500);
      //   setTimeout(() => {
      //     this.loadingText = "Simulation Complete!";
      //   }, 3000);
      //   setTimeout(() => {
      //     this.isLoading = false;
      //     this.simulationHasRun = true;
      //   }, 4500);
    },
    handleSimulate() {
      const nextSim = this.simulations.find(sim => {
        return sim.id !== this.id;
      });

      this.$router.push({
        path: `/simulation/${nextSim.id}`
      });
    },
    async handleOpenReportDialog() {
      const thresh = this.thresholdSelection;
      const tableData = await this.loadReportTableData(thresh);
      // console.log("Tabled data", tableData);
      this.checkTableData = tableData;
      this.simulationReportDialog = true;
    },
    formatCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
      });
      return formatter.format(value);
    },
    closeDialog() {
      this.checkTableData = [];
      this.simulationReportDialog = false;
    }
  }
};
</script>

<style lang="scss">
.theme--dark.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: rgba(white, 0.03) !important;
}
</style>
