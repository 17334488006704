<template>
  <div>
    <highcharts :options="getChartOptions"></highcharts>
  </div>
</template>

<script>
export default {
  name: "TableChart",
  props: {
    chartData: {
      type: Array,
      required: true
    },
    threshold: {
      type: Number,
      required: false
    }
  },
  data() {
    return {};
  },
  mounted() {
    console.log("CHART DATA ", this.chartData);
  },
  computed: {
    chartOptions() {
      const that = this;
      return {
        chart: {
          type: "column",
          //spacing: [0, -10, 0, -10],
          backgroundColor: "transparent",
          height: 350 //(9 / 16) * 100 + "%" // 16:9 ratio
        },
        title: {
          text: undefined
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function() {
            // return `${that.formatCurrency(this.y)}`;
            return `${this.y}%`;
          },

          shared: false,
          useHTML: true
        },
        // plotOptions: {
        //   series: {
        //     states: {
        //       hover: {
        //         enabled: false
        //       }
        //     },
        //     events: {
        //       legendItemClick: function() {
        //         return false;
        //       }
        //     }
        //   }
        // },
        // series: [
        //   {
        //     name: this.yearRange[0],
        //     data: [],
        //     states: {
        //       hover: {
        //         enabled: false
        //       }
        //     },
        //     borderWidth: 0,
        //     color: "#50cbff",
        //     marker: {
        //       enabled: false
        //     }
        //   },
        //   {
        //     name: this.yearRange[1],
        //     data: [],
        //     states: {
        //       hover: {
        //         enabled: false
        //       }
        //     },
        //     borderWidth: 0,
        //     color: "#1796C4",
        //     marker: {
        //       enabled: false
        //     }
        //   }
        // ],
        series: [
          {
            name: "",
            data: [
              this.chartData[0].percent,
              this.chartData[1].percent,
              this.chartData[2].percent,
              this.chartData[3].percent,
              this.chartData[4].percent,
              this.chartData[5].percent
            ],
            states: {
              hover: {
                enabled: false
              }
            },
            borderWidth: 0,
            color: "#50cbff",
            marker: {
              enabled: false
            }
          }
        ],

        yAxis: {
          gridLineColor: "#5c7196",
          title: {
            text: "Threshold %",
            style: {
              color: "#ffffff"
            }
          },
          labels: {
            style: {
              color: "#ffffff"
            }
          },
          crosshair: {
            snap: false,
            dashStyle: "dot",
            color: "#94B0E0"
          },
          plotLines: [
            {
              color: "#F44336",
              width: 2,
              value: this.threshold
            }
          ]
          //   visible: false
          // gridLineWidth: 0,
          // labels: {
          //   enabled: false
          // },
          // title: {
          //   enabled: false
          // }
        },
        xAxis: {
          title: {
            text: "Annual Household Income",
            style: {
              color: "#ffffff"
            }
          },
          labels: {
            style: {
              color: "#ffffff"
            },
            formatter: function() {
              return `${that.formatCurrency(this.value)}`;
            }
          },
          categories: [
            this.chartData[0].amount,
            this.chartData[1].amount,
            this.chartData[2].amount,
            this.chartData[3].amount,
            this.chartData[4].amount,
            this.chartData[5].amount
          ],

          //categories: [this.yearRange[0], this.yearRange[1]],
          //   crosshair: {
          //     snap: false,
          //     dashStyle: "dot",
          //     color: "#94B0E0"
          //   },
          //   visible: false
          gridLineWidth: 1,
          gridLineColor: "#5c7196"
          // labels: {
          //   enabled: false
          // },
          // title: {
          //   enabled: false
          // }
        },
        legend: {
          enabled: false,
          itemStyle: {
            color: "#ffffff",
            cursor: "pointer",
            fontSize: "12px",
            fontWeight: "bold",
            textOverflow: "ellipsis"
          }
        }
      };
    },
    getChartOptions() {
      //   let data = [];
      //   let visits = 10;
      //   for (let i = 1; i < 80; i++) {
      //     visits += Math.round(
      //       (Math.random() < 0.5 ? 1 : -1) * Math.random() * 20
      //     );
      //     data.push({
      //       x: new Date(2018, 0, i),
      //       // name: "name" + i,
      //       y: Math.abs(visits)
      //     });
      //   }

      //   let _chartOptions = this.chartOptions;

      //   _chartOptions.series[0].data = data;
      //   console.log("chartoptons", _chartOptions);
      //   return _chartOptions;

      let options = this.chartOptions;
      // options.series[0].data = this.chartData[0].amount;
      // options.series[1].data = [this.chartData.year2Index];
      console.log("DA OPTiONS", options);
      return options;
    }
  },
  methods: {
    formatCurrency(value) {
      if (typeof value !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0
      });
      return formatter.format(value);
    }
  }
};
</script>

<style lang="scss">
.v-overlay__content {
  padding: 0 2rem;
  width: 100%;
}
</style>
